var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Close = function (props) {
    return (React.createElement("svg", __assign({ width: "12", height: "12", viewBox: "0 0 12 12", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M11.5506 1.6245C11.7068 1.46829 11.7068 1.21502 11.5506 1.05881L10.9413 0.449499C10.785 0.293289 10.5318 0.293289 10.3756 0.449499L6.00008 4.82499L1.62459 0.4495C1.46838 0.29329 1.21512 0.293289 1.05891 0.449499L0.449591 1.05881C0.293381 1.21502 0.293381 1.46829 0.449591 1.6245L4.82508 5.99999L0.449591 10.3755C0.293381 10.5317 0.293381 10.785 0.449591 10.9412L1.05891 11.5505C1.21512 11.7067 1.46838 11.7067 1.62459 11.5505L6.00008 7.17499L10.3756 11.5505C10.5318 11.7067 10.785 11.7067 10.9413 11.5505L11.5506 10.9412C11.7068 10.785 11.7068 10.5317 11.5506 10.3755L7.17508 5.99999L11.5506 1.6245Z", fill: (props === null || props === void 0 ? void 0 : props.fill) || '#1A1A1A' })));
};
