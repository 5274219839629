var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var Union = function (props) {
    return (React.createElement("svg", __assign({ width: "14", height: "14", viewBox: "0 0 14 14", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M2.1 1.4C1.91435 1.4 1.7363 1.47375 1.60503 1.60503C1.47375 1.7363 1.4 1.91435 1.4 2.1V11.9C1.4 12.0857 1.47375 12.2637 1.60503 12.395C1.7363 12.5263 1.91435 12.6 2.1 12.6H4.9H5.2C5.42091 12.6 5.6 12.7791 5.6 13V13.6C5.6 13.8209 5.42091 14 5.2 14H4.9H2.1C1.54304 14 1.0089 13.7788 0.615076 13.3849C0.221249 12.9911 0 12.457 0 11.9V2.1C0 1.54305 0.221249 1.0089 0.615076 0.615076C1.0089 0.221249 1.54305 0 2.1 0H4.9H5.2C5.42091 0 5.6 0.179086 5.6 0.4V1C5.6 1.22091 5.42091 1.4 5.2 1.4H4.9H2.1ZM9.51716 2.79289C9.67337 2.63668 9.92663 2.63668 10.0828 2.79289L10.295 3.00503L13.795 6.50503C14.0683 6.77839 14.0683 7.22161 13.795 7.49497L10.295 10.995L10.0828 11.2071C9.92663 11.3633 9.67337 11.3633 9.51716 11.2071L9.09289 10.7828C8.93668 10.6266 8.93668 10.3734 9.09289 10.2172L9.30503 10.005L11.6101 7.7H4.2H3.9C3.67909 7.7 3.5 7.52091 3.5 7.3V6.7C3.5 6.47909 3.67909 6.3 3.9 6.3H4.2H11.6101L9.30503 3.99497L9.09289 3.78284C8.93668 3.62663 8.93668 3.37337 9.09289 3.21716L9.51716 2.79289Z", fill: "currentColor" })));
};
