var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ChevronRight = function (props) {
    return (React.createElement("svg", __assign({ width: "7", height: "10", viewBox: "0 0 7 10", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M1.45784 0.282843C1.30163 0.126633 1.04837 0.126633 0.892157 0.282843L0.282534 0.892466C0.126445 1.04855 0.126307 1.30158 0.282226 1.45784L3.81667 5L0.282227 8.54216C0.126308 8.69842 0.126445 8.95145 0.282534 9.10753L0.892157 9.71716C1.04837 9.87337 1.30163 9.87337 1.45784 9.71716L5.89216 5.28284C6.04837 5.12663 6.04837 4.87337 5.89216 4.71716L1.45784 0.282843Z" })));
};
