import i18n, { type i18n as i18nType } from 'i18next';

type TParams = Parameters<i18nType['t']>; //[Key, options]
type TReturnType = ReturnType<i18nType['t']>;
export const globalTranslate = (
  key: TParams[0],
  options?: TParams[1],
): TReturnType =>
  i18n.isInitialized ? i18n.t(key, { options: options }) : (key as string);

export const nativeNames = {
  en: 'English',
  enAU: 'English - Australia',
  enCA: 'English - Canada',
  enGB: 'English - Great Britain',
  enUS: 'English',
  es: 'Español',
  esES: 'Español - España',
  esMX: 'Español - México',
  esUS: 'Español - USA',
  fr: 'Français',
  frCA: 'Français (Canada)',
  de: 'Deutsch',
  it: 'Italiano',
};
