import styled, { css } from 'styled-components';

import { primaryFontFamily } from '../utils/brandHelpers';

type NavLinkStyleProps = {
  $p?: string;
};

// Styles used by various files for styling NavLinks
export const NavLinkStyle = css<NavLinkStyleProps>`
  color: ${({ theme }) => theme?.branding_json?.color_3 ?? '#2A3F53'};
  ${({ theme }) =>
    theme &&
    theme.brand_name === 'PPMI' &&
    `font-family: ${primaryFontFamily(theme, { fontWeight: 'semibold' })};`}
  font-size: ${14 / 16}rem;
  font-weight: 500;
  line-height: 16px;
  align-self: center;
  padding: ${(props) => (props.$p ? props.$p : '10px 15px')};
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  opacity: 0.5;
  display: inline-flex;
  align-items: center;
  text-transform: capitalize;

  &:hover,
  &.active {
    opacity: 1;
    color: ${({ theme }) => theme?.branding_json?.color_3 ?? '#2A3F53'};
  }

  &.active {
    cursor: default;
  }
`;

export const NavLinkDiv = styled.div<NavLinkStyleProps>`
  ${NavLinkStyle};
`;

export const NavLinkButton = styled.button`
  ${NavLinkStyle};
  background: transparent;
`;
