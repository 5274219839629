import React, { createContext, useContext, useEffect } from 'react';
import i18n from 'i18next';
import { availableLanguages } from './LanguageContextConstants';
import { logError } from '../utils/errorLogger';
import detector from 'i18next-browser-languagedetector';
import { isTestingEnvironment } from '@evidation/testing-utils';
import testingTranslations from '../i18n/testing';
import { I18nextProvider, initReactI18next } from 'react-i18next';

const defaultLanguage = 'en_US';
export const defaultBillboardNS = 'translation';

/**
 * @description Initializes the translation for billboard, initially it sets everything in english.
 * This is needed since if we waited until the component fully rendered we were getting warnings.
 * As soon as the component renders inside the useEffect we swap the default language for the one we need to display.
 * @param options
 * @param options.isTestEnvironment Whether this is running in a testing
 *   context, such as via Jest. When this is true, we pass a mock language that
 *   simply returns keys for all translated values.
 */
export const initI18N = ({ isTestEnvironment = false } = {}) => {
  try {
    i18n
      .use(detector)
      .use(initReactI18next)
      .init({
        resources: isTestEnvironment
          ? {
              // For testing, we only want a single "language" with the full keys as
              // translated values.
              [defaultLanguage]: { translation: testingTranslations },
            }
          : availableLanguages,
        fallbackLng: defaultLanguage,
        defaultNS: defaultBillboardNS,
        interpolation: { escapeValue: false },
        detection: {
          order: ['querystring', 'navigator'],
          lookupQuerystring: 'locale_override',
        },
        saveMissing: true,
        missingKeyHandler: (langs, ns, key, fallbackValue) => {
          const error = new Error(
            `Unable to find ${defaultLanguage} translation for key '${key}'.`,
          );
          if (isTestEnvironment) {
            logError(error);
            throw error;
          }
        },
      });
  } catch (e) {
    logError(e);
  }
};

// We must init i18n even if it's with a default language, other wise we get a warning.
initI18N({ isTestEnvironment: isTestingEnvironment });

type LanguageContextValue = {
  changeLanguage: typeof i18n.changeLanguage;
  locale: string;
  nameSpace: string;
};

// Let's create a context since we need to pass the function that changes languages down to all components.
export const LanguageContext = createContext<LanguageContextValue>({
  changeLanguage: i18n.changeLanguage,
  locale: i18n.language,
  nameSpace: defaultBillboardNS,
});

/**
 * @description Custom hook that lets you see the current `locale` and
 * change the language outside of this file in a more succinct way.
 * This was done so we don't have to expose `useContext(LanguageContext)` around the app.
 * However, if you don't / can't use the hook you CAN still use the context normally or on a class based component.
 *
 * USAGE: Simply import `useLanguageContext` from this file and then do:
 *
 * const i18nObj = useChangeLanguage();
 * i18nObj.changeLanguage('es');  // <- this changes the language!
 *
 * i18nObj.locale // <- returns the current locale the app is displaying.
 *
 * Alternatively you could also destructure:
 * const {changeLanguage, locale} = useChangeLanguage();
 *
 * Now your functional component has access to this context's locale + changeLanguage function.
 * @returns {{
 *   changeLanguage: typeof import('i18next').changeLanguage,
 *   locale: string,
 *   nameSpace: string,
 * }}
 */
export const useLanguageContext = () => {
  return useContext(LanguageContext);
};

type Props = React.PropsWithChildren<{
  isTestEnvironment?: boolean;
}>;

/**
 * @description This provider element, wraps the whole app , gives it access to i18n functions and fetches the right language to display.
 * @param props
 * @param props.isTestEnvironment Whether we are running in a testing context
 *   such as Jest. This is auto-detected, but can be overridden to do real
 *   translations in a unit test.
 */
const LanguageProvider: React.FC<Props> = ({
  children,
  isTestEnvironment = isTestingEnvironment,
}) => {
  useEffect(() => {
    // Here we get the right language to display after the component fully loads.
    initI18N({ isTestEnvironment });
  }, [isTestEnvironment]);

  // Packaging all the values we want to pass down inside this context!
  const contextValues = {
    changeLanguage: i18n.changeLanguage,
    locale: i18n.language,
    nameSpace: defaultBillboardNS,
  };

  return (
    <I18nextProvider i18n={i18n}>
      <LanguageContext.Provider value={contextValues}>
        {children}
      </LanguageContext.Provider>
    </I18nextProvider>
  );
};

export default LanguageProvider;
