import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const SpinnerFullScreen = styled.div`
  position: absolute;
  margin: 100px auto;
  text-align: center;
  top: 100px;
  left: 0;
  right: 0;
`;

const SpinnerChildDiv = styled.div`
  width: 18px;
  height: 18px;
  background-color: #06466d;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
`;

const Bounce1 = styled(SpinnerChildDiv)`
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
`;

const Bounce2 = styled(SpinnerChildDiv)`
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
`;

const Bounce3 = styled(SpinnerChildDiv)``;

const SpinnerRelative = styled.div`
  text-align: center;
`;

const Container = styled.div`
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  width: auto;
`;

const Container300min = styled(Container)`
  flex: 2 0 auto;
  min-height: 300px;
`;

const Loading = () => {
  const { t } = useTranslation();
  return (
    <Container300min>
      <SpinnerFullScreen
        className="spinner"
        role="progressbar"
        aria-busy={true}
        aria-live="polite"
        aria-valuetext={t('components.common.loading')}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <Bounce1 />
        <Bounce2 />
        <Bounce3 />
      </SpinnerFullScreen>
    </Container300min>
  );
};

export const LoadingIndicatorSmall: React.FC<
  React.HTMLProps<HTMLDivElement>
> = (props) => {
  const { t } = useTranslation();
  return (
    <Container
      className={props.className}
      role="progressbar"
      aria-busy={true}
      aria-live="polite"
      aria-valuetext={t('components.common.loading')}
      aria-valuemin={0}
      aria-valuemax={100}
    >
      <SpinnerRelative>
        <Bounce1 />
        <Bounce2 />
        <Bounce3 />
      </SpinnerRelative>
    </Container>
  );
};

export default Loading;
