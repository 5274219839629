"use strict";

require("core-js/modules/es.array.iterator.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.string.iterator.js");
require("core-js/modules/es.weak-map.js");
require("core-js/modules/web.dom-collections.iterator.js");
require("core-js/modules/es.object.define-property.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
var _typeof = require("@babel/runtime/helpers/typeof");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.localI18n = exports.globalInit = exports["default"] = exports.curiousPandaNamespace = exports.availableLanguages = void 0;
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.object.keys.js");
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _react = _interopRequireWildcard(require("react"));
var _logger = require("../utils/logger");
var _testingUtils = require("@evidation/testing-utils");
var _i18next = _interopRequireDefault(require("i18next"));
var _i18n = require("../i18n");
var _testing = _interopRequireDefault(require("../i18n/testing"));
var _GlobalLanguageContext = require("./GlobalLanguageContext");
var _reactI18next = require("react-i18next");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
var localI18n = exports.localI18n = null;
var defaultLanguage = 'en_US';
var curiousPandaNamespace = exports.curiousPandaNamespace = 'curiousPanda';
var availableLanguages = exports.availableLanguages = _testingUtils.isTestingEnvironment ? (0, _defineProperty2["default"])({}, defaultLanguage, {
  curiousPanda: _testing["default"]
}) : {
  en: {
    curiousPanda: _i18n.enUS
  },
  en_US: {
    curiousPanda: _i18n.enUS
  },
  en_GB: {
    curiousPanda: _i18n.enUS
  },
  es: {
    curiousPanda: _i18n.esUS
  },
  es_US: {
    curiousPanda: _i18n.esUS
  }
};
var globalInit = exports.globalInit = function globalInit() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  // Wrapped in a try/catch because this errors out during testing
  // during normal usage it is not expected to error like this.
  try {
    _i18next["default"].use(_reactI18next.initReactI18next).init({
      resources: availableLanguages,
      lng: locale,
      defaultNS: curiousPandaNamespace,
      fallbackLng: defaultLanguage,
      interpolation: {
        escapeValue: false
      },
      react: {
        useSuspense: false
      },
      saveMissing: true,
      missingKeyHandler: function missingKeyHandler(langs, ns, key, fallbackValue) {
        var error = new Error("Unable to find ".concat(defaultLanguage, " translation for key '").concat(key, "'."));
        if (_testingUtils.isTestingEnvironment) {
          (0, _logger.logError)(error);
          throw error;
        }
      }
    });
    exports.localI18n = localI18n = _i18next["default"];
  } catch (error) {
    (0, _logger.logError)(error);
  }
};
var CuriousPandaLanguageProvider = function CuriousPandaLanguageProvider(_ref2) {
  var children = _ref2.children,
    locale = _ref2.locale;
  var _useContext = (0, _react.useContext)(_GlobalLanguageContext.GlobalLanguageContext),
    i18n = _useContext.i18n;
  (0, _react.useEffect)(function () {
    if (i18n && i18n.isInitialized) {
      var language = i18n.language;
      var isAvailable = Object.keys(availableLanguages).includes(language);
      var langResource = isAvailable ? availableLanguages[language].curiousPanda : availableLanguages[defaultLanguage].curiousPanda;

      // Append language resources to curiousPanda namespace for a specific language.
      i18n.addResourceBundle(language, curiousPandaNamespace, langResource, false, false);
      exports.localI18n = localI18n = i18n;
    } else {
      // Standalone curious panda instance, initiate a local i18next instance
      globalInit(locale);
    }
  }, [i18n, locale]);
  return children;
};
var _default = exports["default"] = CuriousPandaLanguageProvider;