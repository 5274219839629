var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var CloseCircle = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.25 9.01953C0.25 4.1875 4.16797 0.269531 9 0.269531C13.832 0.269531 17.75 4.1875 17.75 9.01953C17.75 13.8516 13.832 17.7695 9 17.7695C4.16797 17.7695 0.25 13.8516 0.25 9.01953ZM1.73438 9.01953C1.73438 13.0312 4.98828 16.2852 9 16.2852C13.0117 16.2852 16.2656 13.0312 16.2656 9.01953C16.2656 5.00781 13.0117 1.75391 9 1.75391C4.98828 1.75391 1.73438 5.00781 1.73438 9.01953ZM11.6457 5.22627C11.7707 5.10131 11.9733 5.10131 12.0983 5.22627L12.7737 5.90173C12.8987 6.02669 12.8987 6.22931 12.7737 6.35427L10.128 9L12.7737 11.6457C12.8987 11.7707 12.8987 11.9733 12.7737 12.0983L12.0983 12.7737C11.9733 12.8987 11.7707 12.8987 11.6457 12.7737L9 10.128L6.35427 12.7737C6.22931 12.8987 6.02669 12.8987 5.90173 12.7737L5.22627 12.0983C5.10131 11.9733 5.10131 11.7707 5.22627 11.6457L7.872 9L5.22627 6.35427C5.10131 6.22931 5.10131 6.02669 5.22627 5.90173L5.90173 5.22627C6.02669 5.10131 6.22931 5.10131 6.35427 5.22627L9 7.872L11.6457 5.22627Z", fill: "#1A1A1A" })));
};
