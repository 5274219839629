"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateDateMinMaxBase = exports.validateDateMinMax = void 0;
var _dateFns = require("date-fns");
var _i18n = require("../../i18n");
var isAfterMin = function isAfterMin(date, minDate) {
  return (0, _dateFns.isBefore)(minDate, date) || (0, _dateFns.isEqual)(minDate, date);
};
var isBeforeMax = function isBeforeMax(date, maxDate) {
  return (0, _dateFns.isAfter)(maxDate, date) || (0, _dateFns.isEqual)(maxDate, date);
};
var validateDateMinMaxBase = exports.validateDateMinMaxBase = function validateDateMinMaxBase(value, minimum, maximum) {
  // If only year is present(birth_year), complement for a valid date
  var completeMinimumDate = String(minimum).length === 4 ? "".concat(minimum, "-01-01") : minimum;
  var completeMaximumDate = String(maximum).length === 4 ? "".concat(maximum, "01-01") : maximum;
  if (value) {
    var dateObject = (0, _dateFns.parseISO)(value);
    var min = (0, _dateFns.parseISO)(completeMinimumDate);
    var max = (0, _dateFns.parseISO)(completeMaximumDate);
    if (minimum !== undefined && maximum !== undefined) {
      if (isAfterMin(dateObject, min) && isBeforeMax(dateObject, max)) {
        return undefined;
      }
    } else {
      if (minimum !== undefined && isAfterMin(dateObject, min)) {
        return undefined;
      } else if (maximum !== undefined && isBeforeMax(dateObject, max)) {
        return undefined;
      } else if (minimum === undefined && maximum === undefined) {
        return undefined;
      }
    }
    return (0, _i18n.globalTranslate)('validations.date.minMax', {
      min: minimum,
      max: maximum
    });
  }
};
var validateDateMinMax = exports.validateDateMinMax = function validateDateMinMax(minimum, maximum) {
  return function (value) {
    return validateDateMinMaxBase(value, minimum, maximum);
  };
};
var validations = exports.validations = function validations(_ref) {
  var minimum = _ref.minimum,
    maximum = _ref.maximum;
  return [validateDateMinMax(minimum, maximum)];
};