var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Label from '../Label';
import { Check } from '../Icons/Check';
import { ChevronDown } from '../Icons/ChevronDown';
import { Chip } from '../Chip';
import Button from '../Button';
import Flex from '../Flex';
import { Close } from '../icons';
import { SelectContainer } from './styles';
import styled from 'styled-components';
var Description = styled.span(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: small;\n  color: #5e5e5e;\n"], ["\n  font-size: small;\n  color: #5e5e5e;\n"])));
var isGroup = function (option) {
    return !option.hasOwnProperty('value');
};
var findSelectedOptions = function (value, options) {
    if (Array.isArray(value)) {
        var selectedOptions = value
            .map(function (v) {
            var opt = findSelectedOption(v, options);
            if (opt) {
                return opt;
            }
            return undefined;
        })
            .filter(function (so) { return so !== undefined; });
        return selectedOptions;
    }
    return undefined;
};
var findSelectedOption = function (value, options) {
    if (typeof value === 'undefined') {
        return undefined;
    }
    for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
        var option = options_1[_i];
        if (isGroup(option)) {
            var selectedOption = findSelectedOption(value, option.subOptions);
            if (selectedOption) {
                return selectedOption;
            }
            // Casting both values as strings to avoid type mismatching, sometimes option.value is a number and value is a string
        }
        else if (String(option.value) === String(value)) {
            return option;
        }
    }
    return undefined;
};
function getDifference(array1, array2) {
    return array1.filter(function (object1) {
        return !array2.some(function (object2) {
            return object1.value === object2.value;
        });
    });
}
export var Select = function (_a) {
    var _b;
    var name = _a.name, value = _a.value, _c = _a.options, options = _c === void 0 ? [] : _c, label = _a.label, _d = _a.error, error = _d === void 0 ? undefined : _d, _e = _a.required, required = _e === void 0 ? false : _e, placeholder = _a.placeholder, _f = _a.labelHidden, labelHidden = _f === void 0 ? false : _f, multiple = _a.multiple, onChange = _a.onChange, renderOptions = _a.renderOptions, _g = _a.description, description = _g === void 0 ? undefined : _g, rest = __rest(_a, ["name", "value", "options", "label", "error", "required", "placeholder", "labelHidden", "multiple", "onChange", "renderOptions", "description"]);
    var selectedOption = multiple
        ? findSelectedOptions(value, options)
        : findSelectedOption(value, options);
    var renderOption = function (option, index, indentLevel) {
        if (indentLevel === void 0) { indentLevel = 0; }
        return isGroup(option) ? (React.createElement(React.Fragment, null,
            React.createElement(Listbox.Option, { key: "option_".concat(indentLevel, "_").concat(index), className: 'list-box-option group', value: undefined, disabled: true },
                React.createElement("span", null, option.label)),
            React.createElement("div", { className: "list-box-group-options" }, option.subOptions.map(function (subOption, innerIndex) {
                return renderOption(subOption, index + 1 + innerIndex, indentLevel + 1);
            })))) : (React.createElement(Listbox.Option, { key: "option_".concat(indentLevel, "_").concat(index), className: function (_a) {
                var active = _a.active;
                return "list-box-option ".concat(active ? 'active' : '');
            }, value: option }, function (_a) {
            var selected = _a.selected;
            return (React.createElement(React.Fragment, null,
                React.createElement("span", { className: selected ? 'selected' : '' }, option.label),
                selected ? (React.createElement("span", { "data-testid": "selected-item-icon" },
                    React.createElement(Check, { "aria-hidden": "true" }))) : null));
        }));
    };
    return (React.createElement(SelectContainer, __assign({ error: error ? true : false }, rest),
        label ? (React.createElement(Label, { required: required, hidden: labelHidden, htmlFor: name }, label)) : null,
        description ? React.createElement(Description, null, description) : null,
        React.createElement(Listbox, { value: selectedOption, onChange: onChange, "data-testid": "t-select-".concat(name), name: name, multiple: multiple },
            React.createElement("div", { style: { position: 'relative' } },
                React.createElement(Listbox.Button, { className: "select", as: multiple ? 'div' : 'button' }, multiple && Array.isArray(selectedOption) ? (React.createElement(Flex, { style: {
                        flexWrap: 'wrap',
                    } }, selectedOption
                    .map(function (option) { return option === null || option === void 0 ? void 0 : option.label; })
                    .map(function (label, selectedIdx) { return (React.createElement(Chip, { key: "selected_option_".concat(selectedIdx), variant: "primary", style: {
                        marginRight: '0.2rem',
                        alignItems: 'center',
                        marginBottom: '0.2rem',
                    } },
                    label,
                    React.createElement(Button, { style: { margin: 0, padding: 0 }, variant: "transparent", onClick: function (evt) {
                            evt.stopPropagation();
                            var without = selectedOption.filter(function (item) { return item.label !== label; });
                            onChange(without);
                        } },
                        React.createElement(Close, { fill: "#fff", width: 12, height: 12 })))); }))) : (React.createElement(React.Fragment, null,
                    React.createElement("span", { className: placeholder ? 'placeholder' : '' }, !Array.isArray(selectedOption) && (React.createElement("span", null, (_b = selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label) !== null && _b !== void 0 ? _b : placeholder))),
                    React.createElement("span", { "data-testid": "toggle-menu-icon", className: "select--toggle-icon" },
                        React.createElement(ChevronDown, { "aria-hidden": "true" }))))),
                options && (React.createElement(Transition, { as: "div", leave: "transition ease-in duration-100", leaveFrom: "opacity-100", leaveTo: "opacity-0" },
                    multiple && Array.isArray(selectedOption) ? (React.createElement(Listbox.Options, { className: "list-box-options" }, getDifference(options, selectedOption).map(function (option, optionIdx) { return renderOption(option, optionIdx); }))) : (React.createElement(Listbox.Options, { className: "list-box-options" }, options.map(function (option, optionIdx) {
                        return renderOption(option, optionIdx);
                    }))),
                    renderOptions && (React.createElement(Listbox.Options, { className: "list-box-options" }, function (_a) {
                        var open = _a.open;
                        return React.createElement(React.Fragment, null, renderOptions({ open: open }));
                    })))))),
        error && React.createElement("div", { className: "error" }, error)));
};
var templateObject_1;
