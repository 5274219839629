import CuriousPanda from '@evidation/curious-panda';
import type { CuriousPandaProps } from '@evidation/curious-panda';
import React from 'react';
import { useLanguageContext } from '../contexts/LanguageContext';
import i18n from 'i18next';
type LocalCuriousPandaProps = Omit<CuriousPandaProps, 'i18nInstance'>;

const LocalCuriousPanda = (props: LocalCuriousPandaProps) => {
  // Need a default for the test environment, where we don't necessarily have a
  // language context.
  // @ts-ignore
  // todo fix the lanague context to properly describe the state.
  const locale = useLanguageContext()?.locale;
  return (
    // @ts-ignore
    <CuriousPanda
      autoComplete="off"
      translationLocale={locale}
      i18nInstance={i18n}
      {...props}
    />
  );
};

export {
  BillboardNavigation,
  GetFieldDictionary,
  SubmissionError,
} from '@evidation/curious-panda';

export default LocalCuriousPanda;
