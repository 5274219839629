"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
/*
  Used to when calling TransformSchema to build field schema in Form.js
  https://github.com/evidation-platform/study-manager/blob/main/packages/curious-panda/src/Form.js#L249
*/
var _default = exports["default"] = function _default() {
  var intf = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  switch (intf.toLowerCase()) {
    case "content_acceptance":
      return "contentacceptance";
    case "height":
      return "heightinput";
    case "radio":
      return "radioinput";
    case "select":
      return "selectinput";
    case "textarea":
      return "textareainput";
    case "checkboxinput":
    case "checkboxgroup":
    case "checkbox":
    case 'checkbox2':
    case 'checkboxwithnone':
      return "checkboxgroupwithnone";
    case "zipcode":
    case "zip_code":
      return "zip";
    case "mask":
      return "maskinput";
    case "tel":
    case "phone":
      return "telinput";
    case "input":
      return "textinput";
    case "email":
      return "emailinput";
    case "passwordconfirm":
      return "password";
    default:
      // this is a catch all for ones that do not need transoformation
      // fields.js does a lowecase on the string anyways, so it's not always
      // required to return a special transformation
      return intf.toLowerCase();
  }
};