import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './App.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import React from 'react';
import App from './app';
import { BASE_HREF } from './constants';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './errorBoundary';
import './utils/errorLogger';

const app = document.getElementById('root');
const root = createRoot(app as HTMLElement);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={BASE_HREF}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </Provider>,
);
