var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var ChevronDown = function (props) {
    return (React.createElement("svg", __assign({ width: "10", height: "7", viewBox: "0 0 10 7", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M9.71716 1.45784C9.87337 1.30163 9.87337 1.04837 9.71716 0.892157L9.10753 0.282534C8.95145 0.126445 8.69842 0.126307 8.54216 0.282226L5 3.81667L1.45784 0.282226C1.30158 0.126307 1.04856 0.126445 0.892466 0.282534L0.282843 0.892157C0.126634 1.04837 0.126634 1.30163 0.282843 1.45784L4.71716 5.89216C4.87337 6.04837 5.12663 6.04837 5.28284 5.89216L9.71716 1.45784Z" })));
};
