// @ts-nocheck
import ContactModal from './contactModal';
import GoogleTagManager from './GoogleTagManager';
import { Logo } from './header/headerLinks';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslated } from '../hooks';
import { useLayoutEffect } from 'react';

const footerLinkStyles = css`
  white-space: nowrap;
  text-decoration: none;
  padding: 0 10px;
  color: ${(p) => p?.theme?.branding_json?.color_3 ?? '#2A3F53'};
  :hover {
    opacity: 0.7;
    color: ${(p) => p?.theme?.branding_json?.color_3 ?? '#2A3F53'};
  }
`;

const FooterLink = styled.a.attrs({
  target: '_blank',
  rel: 'noreferrer noopener',
})`
  ${footerLinkStyles}
`;

const FooterButton = styled.button`
  appearance: none;
  ${footerLinkStyles}
  border: 0;
  background-color: transparent;
  font: inherit;
  cursor: pointer;
  :focus:not(:focus-visible) {
    outline: none;
  }
`;

const StyledFooter = styled.footer`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  border-top: 1px solid #e3e3e3;
  background-color: #ffffff;
  padding: 15px;
  min-height: 100px;
  @media (min-width: 375px) {
    white-space: nowrap;
  }

  color: #2a3f53;
  font-size: 1rem;
  font-weight: 500;
  line-height: 16px;

  & > * {
    flex: 1;
    align-items: center;
  }

  @media (max-width: 940px) {
    flex-flow: column;
    & > * {
      padding: 10px;
    }
  }
`;

const FooterConstraints = styled.div`
  max-width: 1260px;
  display: flex;
  flex-flow: row nowrap;
  margin: 0 auto;
  display: flex;

  @media (max-width: 940px) {
    flex-flow: column;
    & > div:nth-child(-n + 2) {
      padding-bottom: 10px;
    }
  }

  @media (max-width: 440px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

const HiddenTimestamp = styled.span`
  height: 0;
  width: 0;
  padding: 0;
  color: #fff;
  font-size: ${8 / 16}rem;
`;

const Centered = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
  justify-content: flex-start;
  text-align: center;

  img {
    max-width: 100%;
  }

  :nth-of-type(3) {
    justify-content: flex-end;
  }

  @media (max-width: 940px) {
    justify-content: center;
  }

  @media (max-width: 400px) {
    font-size: ${14 / 16}rem;
  }

  @media (max-width: 374px) {
    font-size: ${11 / 16}rem;
  }
`;

const builtAt = process.env.REACT_APP_VERSION;

const currentYear = new Date().getFullYear();

const Footer = ({
  gtm_id,
  gtm_id_cookie_banner,
  communication_id = '',
  brand,
}) => {
  const defaultCopyright = useTranslated('components.footer.defaultCopyright');
  const contactUsText = useTranslated('components.common.contactUs');
  const copyrightText =
    brand?.branding_json?.copyright_text ?? defaultCopyright;
  const privacyUrl =
    brand?.branding_json?.privacy_policy_url ??
    `https://www.myachievement.com/privacy`;
  const termsUrl =
    brand?.branding_json?.terms_url ?? `https://www.myachievement.com/terms`;

  useLayoutEffect(() => {
    if (window?.modifyFooter && typeof window.modifyFooter === 'function') {
      window.modifyFooter();
    }
  }, [gtm_id, gtm_id_cookie_banner, communication_id, brand]);

  return (
    <StyledFooter className="footer">
      <FooterConstraints>
        <Centered id="t-copyright">
          {copyrightText} © {currentYear}
        </Centered>
        <Centered>
          <ContactModal>
            {({ showModal }) => (
              <FooterButton onClick={showModal}>{contactUsText}</FooterButton>
            )}
          </ContactModal>
          {communication_id !== `` && <p>{communication_id}</p>}
          <FooterLink href={termsUrl}>
            <span id="t-terms">
              {useTranslated('components.footer.termsConditions')}
            </span>
          </FooterLink>
          <FooterLink href={privacyUrl}>
            <span id="t-privacy">
              {useTranslated('components.footer.privacyPolicy')}
            </span>
          </FooterLink>
        </Centered>
        <Centered>
          <Logo style={{ marginBottom: '5px' }} />
        </Centered>
        <HiddenTimestamp>{`${builtAt}`}</HiddenTimestamp>
        {gtm_id && <GoogleTagManager gtmId={gtm_id} />}
        {gtm_id_cookie_banner && (
          <GoogleTagManager gtmId={gtm_id_cookie_banner} />
        )}
      </FooterConstraints>
    </StyledFooter>
  );
};

Footer.defaultProps = {
  gtm_id: undefined,
  communication_id: '',
  contact_phone_number: '',
  brand: {},
};

Footer.propTypes = {
  communication_id: PropTypes.string,
};

export default Footer;
