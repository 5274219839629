"use strict";

require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.symbol.js");
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.object.get-own-property-descriptor.js");
require("core-js/modules/es.array.for-each.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.get-own-property-descriptors.js");
require("core-js/modules/es.object.define-properties.js");
require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BillboardNavigation", {
  enumerable: true,
  get: function get() {
    return _BillboardNavigation["default"];
  }
});
Object.defineProperty(exports, "ComponentDictionary", {
  enumerable: true,
  get: function get() {
    return _fields["default"];
  }
});
Object.defineProperty(exports, "CuriousPandaFinalForm", {
  enumerable: true,
  get: function get() {
    return _Form["default"];
  }
});
Object.defineProperty(exports, "GetFieldDictionary", {
  enumerable: true,
  get: function get() {
    return _generateFieldDictionary["default"];
  }
});
Object.defineProperty(exports, "SubmissionError", {
  enumerable: true,
  get: function get() {
    return _SubmissionError["default"];
  }
});
Object.defineProperty(exports, "constructFieldClassname", {
  enumerable: true,
  get: function get() {
    return _utils.constructFieldClassname;
  }
});
exports["default"] = void 0;
Object.defineProperty(exports, "emailValidation", {
  enumerable: true,
  get: function get() {
    return _validations.emailValidation;
  }
});
Object.defineProperty(exports, "requiredValidation", {
  enumerable: true,
  get: function get() {
    return _validations2.required;
  }
});
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _propTypes = _interopRequireDefault(require("prop-types"));
var _App = require("./App");
var _BillboardNavigation = _interopRequireDefault(require("./BillboardNavigation"));
var _FormOld = _interopRequireDefault(require("./FormOld"));
var _Form = _interopRequireDefault(require("./Form"));
var _generateFieldDictionary = _interopRequireDefault(require("./generateFieldDictionary"));
var _utils = require("./components/utils");
var _validations = require("./fields/emailConfirm/validations");
var _validations2 = require("./validations");
var _fields = _interopRequireDefault(require("./fields"));
var _SubmissionError = _interopRequireDefault(require("./SubmissionError"));
var _utils2 = require("./utils");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2["default"])(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
_FormOld["default"].defaultProps = _App.defaultProps;
_FormOld["default"].propTypes = _App.propTypes;
_Form["default"].defaultProps = _App.defaultProps;
_Form["default"].propTypes = _objectSpread(_objectSpread({}, _App.propTypes), {}, {
  form: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].func])
});
var _default = exports["default"] = _FormOld["default"];