"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validations = exports.validateMinMax = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _i18n = require("../../i18n");
var validateMinMax = exports.validateMinMax = function validateMinMax(min, max) {
  return function (value, allValues, props, name) {
    if (allValues !== null && allValues !== void 0 && allValues["".concat(name, "_exclusive")]) {
      return undefined;
    }
    if (value) {
      var val = _lodash["default"].toNumber(value);
      if (min !== undefined && max !== undefined) {
        if (min <= val && max >= val) {
          return undefined;
        }
      } else {
        if (min !== undefined && val > min) {
          return undefined;
        } else if (max !== undefined && val < max) {
          return undefined;
        } else if (min === undefined && max === undefined) {
          return undefined;
        }
      }
      return (0, _i18n.globalTranslate)('validations.number.minMax', {
        min: min,
        max: max
      });
    }
  };
};
var validations = exports.validations = function validations(_ref) {
  var minimum = _ref.minimum,
    maximum = _ref.maximum;
  return [validateMinMax(minimum, maximum)];
};