// variables set on a per-environment basis

const slug = window.location.pathname.split('/')[1];
window.study_slug = '/' + slug;

export const STUDY_ID = slug;
export const BASE_HREF = window.study_slug;

export const TRIAGE_ENDPOINT =
  window.env.REACT_APP_TRIAGE_ENDPOINT || window.location.origin;
// TODO: will need to receive these from triage via get to 'meta'
export const TRIAGE_API_VERSION = 'v2';
export const ACHIEVEMENT_ENDPOINT = window.env.REACT_APP_ACHIEVEMENT_ENDPOINT;
export const FACEBOOK_APP_ID = window.env.REACT_APP_FACEBOOK_APP_ID;

// auth
export const AUTH_TYPES = {
  basic: 'basic_auth',
  facebook: 'FacebookAuthentication',
};
export const ALL_AUTH_METHODS = Object.values(AUTH_TYPES);

export const STUDY_STATUSES = {
  draft: 'draft',
  // start_qa
  qa: 'qa',
  // open_enrollment
  recruiting: 'recruiting',
  // start_study
  // restart_enrollment
  active: 'active',
  // close_enrollment
  enrollment_closed: 'enrollment_closed',
  // close data
  data_close: 'data_close',
  // lock_data
  data_lock: 'data_lock',
  // complete
  completed: 'completed',
};

export const ENROLLMENT_STATUSES = {
  opted_out: 'opted_out',
  timed_out: 'timed_out',
  timing_out: 'timing_out',
  withdrawing: 'withdrawing',
  disqualified: 'disqualified',
  disqualifying: 'disqualifying',
  study_completed: 'study_completed',
  enrollment_completed: 'enrollment_completed',
  invitation_clicked: 'invitation_clicked',
  invited: 'invited',
  pending: 'pending',
  suspended: 'suspended',
};

export const ENROLLMENT_NODE_STATUSES_DISPLAYABLE = [
  `available`,
  `in_progress`,
  `disqualified`,
];

export const STUDY_STATUSES_ENROLLMENT_CLOSED = [
  STUDY_STATUSES.enrollment_closed,
  STUDY_STATUSES.data_close,
  STUDY_STATUSES.data_lock,
  STUDY_STATUSES.completed,
];

export const ENROLLMENT_STATUSES_NON_LOGINABLE = [
  ENROLLMENT_STATUSES.disqualified,
  ENROLLMENT_STATUSES.opted_out,
  ENROLLMENT_STATUSES.timed_out,
  ENROLLMENT_STATUSES.pending,
];

//TODO: Refactor to remove word cookie
export const COOKIE_PORTAL_EMAIL = `PORTAL_EMAIL`;
export const COOKIE_ACM_ID = `_acm_id`;
export const COOKIE_PARTICIPANT_AUTH = `participant_auth_token`;

export const STORAGE_PARTICIPANT_AUTH = 'participant_auth_token';
export const STORAGE_PARTICIPANT_AUTH_EXPIRATION =
  'participant_auth_token_expiration';
export const STORAGE_PARTICIPANT_REDIRECT_NODE = `participant_redirect_node`;
// other
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// eslint-disable-next-line
export const URL_REGEX =
  /https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}/;

export const NON_LOGINABLE_LAYOUTS = ['dashboard', 'follow_up'];
export const NON_LOGOUTABLE_LAYOUTS = ['follow_up'];

export const RECAPTCHA_VERSIONS = {
  OFF: 'Off',
  CHECKBOX: 'Enterprise Checkbox',
  SCORE: 'Enterprise Score Based',
};

//TODO: Understand and remove this
export const AWS_COGNITO_PROVIDER = 'aws cognito';
export const EXTERNAL_AUTH_PROVIDER_ALLOWLIST = [
  AWS_COGNITO_PROVIDER,
  'ppmi',
  'ppmi-online',
];

export const CUSTOM_SUBMISSION_TYPES = [
  { type: 'number', interface: 'number' },
  { type: 'number', interface: 'select-number-range' },
  { type: 'number', interface: 'radio' },
  { type: 'number[]', interface: 'checkbox' },
  { type: 'number[]Ornumber', interface: 'selectadvanced' },
  { type: 'number[]', interface: 'checkboxWithNone' },
];
