var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { RadioGroup } from '@headlessui/react';
import styled from 'styled-components';
import { color } from '../system';
var RadioGroupWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  & .label {\n    font-weight: 400;\n    display: block;\n    margin-bottom: 1rem;\n  }\n\n  & .option {\n    margin-bottom: 1rem;\n  }\n\n  & .item-label {\n    display: flex;\n    align-items: center;\n  }\n\n  & .item-desc {\n    margin-left: 2rem;\n    color: #777;\n  }\n\n  & .error {\n    color: ", ";\n    font-size: 12px;\n  }\n"], ["\n  margin-bottom: 1rem;\n  & .label {\n    font-weight: 400;\n    display: block;\n    margin-bottom: 1rem;\n  }\n\n  & .option {\n    margin-bottom: 1rem;\n  }\n\n  & .item-label {\n    display: flex;\n    align-items: center;\n  }\n\n  & .item-desc {\n    margin-left: 2rem;\n    color: #777;\n  }\n\n  & .error {\n    color: ", ";\n    font-size: 12px;\n  }\n"])), color.text.error);
var RadioButton = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  box-sizing: border-box;\n  padding: 8px;\n"], ["\n  box-sizing: border-box;\n  padding: 8px;\n"])));
var RadioCircleOutter = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  border: 1px solid #555;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  width: 16px;\n  height: 16px;\n  border: 1px solid #555;\n  border-radius: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var RadioCircleInner = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-radius: 100%;\n  height: 8px;\n  width: 8px;\n  background: ", ";\n"], ["\n  border-radius: 100%;\n  height: 8px;\n  width: 8px;\n  background: ", ";\n"])), color.status.informational);
var Description = styled.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: small;\n  color: #5e5e5e;\n"], ["\n  font-size: small;\n  color: #5e5e5e;\n"])));
var EviRadioGroup = function (_a) {
    var value = _a.value, onChange = _a.onChange, name = _a.name, _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.options, options = _c === void 0 ? [] : _c, _d = _a.error, error = _d === void 0 ? null : _d, inline = _a.inline, description = _a.description;
    return (React.createElement(RadioGroupWrapper, null,
        React.createElement(RadioGroup, { value: value, onChange: onChange, name: name, "data-testid": "t-evi-ui-radio-group-".concat(name) },
            React.createElement(RadioGroup.Label, { className: "label" }, label),
            description && React.createElement(Description, null, description),
            React.createElement("div", { style: {
                    display: 'flex',
                    flexDirection: inline ? 'row' : 'column',
                    gap: '5px',
                } }, options.map(function (option, id) {
                return (React.createElement(RadioGroup.Option, { value: option.value, key: "evi_ui_radio_group_opt_".concat(id), "data-testid": "t-evi-ui-radio-group-opt-".concat(id), className: "option" },
                    React.createElement(RadioGroup.Label, { className: "item-label" },
                        React.createElement(RadioButton, null,
                            React.createElement(RadioCircleOutter, null, value === option.value && React.createElement(RadioCircleInner, null))),
                        option.label),
                    React.createElement(RadioGroup.Description, { className: "item-desc" }, option.description)));
            }))),
        error && React.createElement("p", { className: "error" }, error)));
};
export default EviRadioGroup;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
