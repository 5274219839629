var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Switch } from '@headlessui/react';
import React from 'react';
import styled from 'styled-components';
import { color } from '../system';
var SwitchContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n\n  flex-direction: ", ";\n  & :focus {\n    border-radius: 4px;\n    box-shadow: 0 0 0 calc(1px + 2px) ", ";\n  }\n"], ["\n  display: flex;\n  align-items: center;\n\n  flex-direction: ", ";\n  & :focus {\n    border-radius: 4px;\n    box-shadow: 0 0 0 calc(1px + 2px) ", ";\n  }\n"])), function (props) { return (props.inline ? 'row' : 'column'); }, color.borders.decorative);
var InnerSwitch = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  height: 20px;\n  width: 40px;\n  cursor: pointer;\n  border-radius: 999px;\n  display: flex;\n  align-items: center;\n"], ["\n  background: ", ";\n  height: 20px;\n  width: 40px;\n  cursor: pointer;\n  border-radius: 999px;\n  display: flex;\n  align-items: center;\n"])), function (props) { return (props.active ? '#162D6D' : '#e2e2e2'); });
var SwitchToggle = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 16px;\n  height: 16px;\n  background: #fff;\n  border-radius: 999px;\n  margin-left: 4px;\n  margin-right: 4px;\n  transition: all;\n  pointer-events: none;\n  transition-duration: 200ms;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transform: ", ";\n"], ["\n  width: 16px;\n  height: 16px;\n  background: #fff;\n  border-radius: 999px;\n  margin-left: 4px;\n  margin-right: 4px;\n  transition: all;\n  pointer-events: none;\n  transition-duration: 200ms;\n  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);\n  transform: ", ";\n"])), function (props) {
    return props.active ? "translateX(1rem)" : "translateX(0rem)";
});
var SwitchLabel = styled.label(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-left: 1rem;\n  font-weight: 400px;\n"], ["\n  margin-left: 1rem;\n  font-weight: 400px;\n"])));
var ToggleWrapper = React.forwardRef(function (_a, ref) {
    var active = _a.active, children = _a.children, rest = __rest(_a, ["active", "children"]);
    return (React.createElement("div", __assign({ ref: ref }, rest),
        React.createElement(InnerSwitch, { active: active }, children)));
});
var Toggle = function (_a) {
    var checked = _a.checked, _b = _a.label, label = _b === void 0 ? '' : _b, onChange = _a.onChange, _c = _a.inline, inline = _c === void 0 ? true : _c, name = _a.name;
    return (React.createElement(Switch.Group, null,
        React.createElement(SwitchContainer, { inline: inline, "data-testid": "t-toggle-".concat(name) },
            !inline && React.createElement(Switch.Label, { as: SwitchLabel }, label),
            React.createElement(Switch, { name: name, as: ToggleWrapper, active: checked, checked: checked, onChange: onChange },
                React.createElement(SwitchToggle, { active: checked })),
            inline && React.createElement(Switch.Label, { as: SwitchLabel }, label))));
};
export default Toggle;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
