var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var SwitchBoard = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M3.5306 0.875C3.75151 0.875 3.9306 1.05409 3.9306 1.275V7.975C3.9306 8.19591 3.75151 8.375 3.5306 8.375H2.94171C2.72079 8.375 2.54171 8.19591 2.54171 7.975V1.275C2.54171 1.05409 2.72079 0.875 2.94171 0.875H3.5306ZM3.9306 11.7083V17.1417C3.9306 17.3626 3.75151 17.5417 3.5306 17.5417H2.94171C2.72079 17.5417 2.54171 17.3626 2.54171 17.1417V11.7083H0.858374C0.63746 11.7083 0.458374 11.5292 0.458374 11.3083V10.4417C0.458374 10.2208 0.63746 10.0417 0.858374 10.0417H2.54171H3.9306H5.61393C5.83484 10.0417 6.01393 10.2208 6.01393 10.4417V11.3083C6.01393 11.5292 5.83484 11.7083 5.61393 11.7083H3.9306ZM9.08615 17.5417C9.30707 17.5417 9.48615 17.3626 9.48615 17.1417V8.775C9.48615 8.55409 9.30707 8.375 9.08615 8.375H8.49726C8.27635 8.375 8.09726 8.55409 8.09726 8.775V17.1417C8.09726 17.3626 8.27635 17.5417 8.49726 17.5417H9.08615ZM9.08615 0.875C9.30707 0.875 9.48615 1.05409 9.48615 1.275V5.04167H11.1695C11.3904 5.04167 11.5695 5.22075 11.5695 5.44167V6.30833C11.5695 6.52925 11.3904 6.70833 11.1695 6.70833H9.48615H8.09726H6.41393C6.19302 6.70833 6.01393 6.52925 6.01393 6.30833V5.44167C6.01393 5.22075 6.19302 5.04167 6.41393 5.04167H8.09726V1.275C8.09726 1.05409 8.27635 0.875 8.49726 0.875H9.08615ZM14.6417 17.5417C14.8626 17.5417 15.0417 17.3626 15.0417 17.1417V13.375H16.725C16.946 13.375 17.125 13.1959 17.125 12.975V12.1083C17.125 11.8874 16.946 11.7083 16.725 11.7083H15.0417H13.6528H11.9695C11.7486 11.7083 11.5695 11.8874 11.5695 12.1083V12.975C11.5695 13.1959 11.7486 13.375 11.9695 13.375H13.6528V17.1417C13.6528 17.3626 13.8319 17.5417 14.0528 17.5417H14.6417ZM14.6417 0.875C14.8626 0.875 15.0417 1.05409 15.0417 1.275V9.64167C15.0417 9.86258 14.8626 10.0417 14.6417 10.0417H14.0528C13.8319 10.0417 13.6528 9.86258 13.6528 9.64167V1.275C13.6528 1.05409 13.8319 0.875 14.0528 0.875H14.6417Z", fill: "currentColor" })));
};
