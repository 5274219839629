"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zipRegex = exports.timeRegex = exports.textOnlyRegex = exports.safe_name_regex = exports.regexUnderscores = exports.range_1to10 = exports.range_0to5 = exports.range_0to10 = exports.phoneRegex = exports.emailRegex = exports.default_select_value = exports.defaultMinFiles = exports.defaultMaxFiles = exports.defaultMaxFileSize = exports.dateFormatString = exports.ascendingOptionListOfInt = exports.alphanumericRegex = exports.allowedNoName = exports.HumanaIDLength = exports.HTML_regex = exports.CUSTOM_SUBMISSION_TYPES = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/es.array.concat.js");
require("core-js/modules/es.date.to-string.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.to-string.js");
var _lodash = _interopRequireDefault(require("lodash"));
/*eslint no-useless-escape: 0*/

var default_select_value = exports.default_select_value = "---Choose One---";
var safe_name_regex = exports.safe_name_regex = /[^a-zA-Z0-9._]+/g;
var phoneRegex = exports.phoneRegex = /^([0|\+[1]{1,2})?([0-9]{10})$/;
var emailRegex = exports.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
var timeRegex = exports.timeRegex = /(0[1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|([1-9]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))|(1[0-2]:[0-5][0-9]((\ ){0,1})((AM)|(PM)|(am)|(pm)))/;
var zipRegex = exports.zipRegex = /^\d{5}$/;
var HumanaIDLength = exports.HumanaIDLength = 9;
var regexUnderscores = exports.regexUnderscores = /[_]/g;
var HTML_regex = exports.HTML_regex = /<[a-z][\s\S]*>/i;

// fields allowed to render without a `name` field
var allowedNoName = exports.allowedNoName = ["scrollbox", "scrollboxsmall", "scrollboxlarge", "paragraph", "label", "heading", "markdown"];

// if no maxFiles is supplied, this is what is used
var defaultMinFiles = exports.defaultMinFiles = 1;
var defaultMaxFiles = exports.defaultMaxFiles = 2;
var defaultMaxFileSize = exports.defaultMaxFileSize = 10485760;
var fillRange = function fillRange(start, end) {
  return _lodash["default"].times(end - start + 1, function (index) {
    return start + index;
  });
};
var ascendingOptionListOfInt = exports.ascendingOptionListOfInt = function ascendingOptionListOfInt() {
  var max = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 100;
  var start = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var arr = fillRange(start, max);
  return _lodash["default"].reduce(arr, function (acc, unknown, count) {
    var num = start + count;
    return [].concat((0, _toConsumableArray2["default"])(acc), [{
      label: num.toString(),
      value: num
    }]);
  }, []);
};
var range_1to10 = exports.range_1to10 = ascendingOptionListOfInt(10, 1);
var range_0to10 = exports.range_0to10 = ascendingOptionListOfInt(10);
var range_0to5 = exports.range_0to5 = ascendingOptionListOfInt(5);
var dateFormatString = exports.dateFormatString = "yyyy-MM-dd'T'HH:mm:ssxxx";
var alphanumericRegex = exports.alphanumericRegex = /^[a-z0-9]+$/i;
var textOnlyRegex = exports.textOnlyRegex = /^[^0-9!@#$%^&*()_=\.,\{\}\[\]\;\:<>\/\\\|\"\?]*$/i;
var CUSTOM_SUBMISSION_TYPES = exports.CUSTOM_SUBMISSION_TYPES = [{
  type: 'number',
  "interface": 'number'
}, {
  type: 'number',
  "interface": 'select-number-range'
}, {
  type: 'number',
  "interface": 'radio'
}, {
  type: 'number[]',
  "interface": 'checkbox'
}, {
  type: 'number[]Ornumber',
  "interface": 'selectadvanced'
}, {
  type: 'number[]',
  "interface": 'checkboxWithNone'
}];