var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var PlusCircle = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: "currentColor", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { d: "M12.5938 8.37498H9.625V5.40623C9.625 5.3203 9.55469 5.24998 9.46875 5.24998H8.53125C8.44531 5.24998 8.375 5.3203 8.375 5.40623V8.37498H5.40625C5.32031 8.37498 5.25 8.4453 5.25 8.53123V9.46873C5.25 9.55467 5.32031 9.62498 5.40625 9.62498H8.375V12.5937C8.375 12.6797 8.44531 12.75 8.53125 12.75H9.46875C9.55469 12.75 9.625 12.6797 9.625 12.5937V9.62498H12.5938C12.6797 9.62498 12.75 9.55467 12.75 9.46873V8.53123C12.75 8.4453 12.6797 8.37498 12.5938 8.37498Z" }),
        React.createElement("path", { d: "M9 0.249985C4.16797 0.249985 0.25 4.16795 0.25 8.99998C0.25 13.832 4.16797 17.75 9 17.75C13.832 17.75 17.75 13.832 17.75 8.99998C17.75 4.16795 13.832 0.249985 9 0.249985ZM9 16.2656C4.98828 16.2656 1.73438 13.0117 1.73438 8.99998C1.73438 4.98827 4.98828 1.73436 9 1.73436C13.0117 1.73436 16.2656 4.98827 16.2656 8.99998C16.2656 13.0117 13.0117 16.2656 9 16.2656Z" })));
};
