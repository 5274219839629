"use strict";

require("core-js/modules/es.object.define-property.js");
var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logError = void 0;
var _logger = _interopRequireDefault(require("@evidation/logger"));
var logger = _logger["default"];
if (window.logger) {
  logger = window.logger;
}
var logError = exports.logError = function logError(message) {
  logger.error(message);
};