var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { useRef, useState, } from 'react';
import styled from 'styled-components';
var TooltipBtn = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  border: none;\n  background-color: transparent;\n  padding: 0;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"], ["\n  position: relative;\n  border: none;\n  background-color: transparent;\n  padding: 0;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"])));
var TooltipContent = styled.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: absolute;\n  top: 50%;\n  left: 0;\n  opacity: 0;\n  width: max-content;\n  display: block;\n  padding: 0.5rem;\n  color: #ffffff;\n  background-color: #1a1a1a;\n  border-radius: 4px;\n  transition: all 0.5s ease;\n\n  &.show {\n    opacity: 100;\n  }\n\n  &::before {\n    content: '';\n    width: 0;\n    height: 0;\n    border-left: 10px solid transparent;\n    border-right: 10px solid #1a1a1a;\n    border-bottom: 10px solid transparent;\n    border-top: 10px solid transparent;\n    position: absolute;\n    bottom: calc(50% - 10px);\n    left: -20px;\n  }\n"], ["\n  position: absolute;\n  top: 50%;\n  left: 0;\n  opacity: 0;\n  width: max-content;\n  display: block;\n  padding: 0.5rem;\n  color: #ffffff;\n  background-color: #1a1a1a;\n  border-radius: 4px;\n  transition: all 0.5s ease;\n\n  &.show {\n    opacity: 100;\n  }\n\n  &::before {\n    content: '';\n    width: 0;\n    height: 0;\n    border-left: 10px solid transparent;\n    border-right: 10px solid #1a1a1a;\n    border-bottom: 10px solid transparent;\n    border-top: 10px solid transparent;\n    position: absolute;\n    bottom: calc(50% - 10px);\n    left: -20px;\n  }\n"])));
var Tooltip = function (_a) {
    var _b, _c;
    var content = _a.content, children = _a.children;
    var _d = useState(false), showTooltip = _d[0], setShowTooltip = _d[1];
    var contentRef = useRef(null);
    return (React.createElement(TooltipBtn, { onMouseEnter: function () { return setShowTooltip(true); }, onMouseLeave: function () { return setShowTooltip(false); }, role: "tooltip" },
        children,
        React.createElement(TooltipContent, { show: showTooltip, "data-testId": "tooltip-content", className: showTooltip ? 'show' : '', ref: contentRef, style: {
                transform: "translate(30px, -".concat(((_c = (_b = contentRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight) !== null && _c !== void 0 ? _c : 0) / 2, "px)"),
            } }, content)));
};
export default Tooltip;
var templateObject_1, templateObject_2;
