var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import FieldGroup from '../FieldGroup';
import styled, { css } from 'styled-components';
import Label from '../Label';
import { color } from '../system';
import Eye from '../Icons/Eye';
var styles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  height: 40px;\n  border-radius: 4px;\n  outline: none;\n  padding-left: 6px;\n  color: #5e5e5e;\n  border: 1px solid\n    ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:focus {\n    border: 2px solid\n      ", ";\n  }\n  &:disabled {\n    color: #737373;\n  }\n"], ["\n  width: 100%;\n  height: 40px;\n  border-radius: 4px;\n  outline: none;\n  padding-left: 6px;\n  color: #5e5e5e;\n  border: 1px solid\n    ", ";\n  &:hover {\n    background: ", ";\n  }\n  &:focus {\n    border: 2px solid\n      ", ";\n  }\n  &:disabled {\n    color: #737373;\n  }\n"])), function (props) { return (props.error ? color.borders.error : color.borders.default); }, function (props) {
    return props.error ? color.status.error_subdued : '#ebefff';
}, function (props) { return (props.error ? color.borders.error : color.borders.focus); });
var StyledInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), styles);
var StyledTextarea = styled.textarea(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  padding: 8px;\n  height: auto;\n"], ["\n  ", "\n  padding: 8px;\n  height: auto;\n"])), styles);
var Description = styled.span(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: small;\n  color: #5e5e5e;\n"], ["\n  font-size: small;\n  color: #5e5e5e;\n"])));
var Input = React.forwardRef(function (_a, ref) {
    var label = _a.label, _b = _a.inline, inline = _b === void 0 ? false : _b, id = _a.id, required = _a.required, name = _a.name, error = _a.error, _c = _a.type, type = _c === void 0 ? 'text' : _c, _d = _a.labelHidden, labelHidden = _d === void 0 ? false : _d, _e = _a.flow, flow = _e === void 0 ? false : _e, description = _a.description, rest = __rest(_a, ["label", "inline", "id", "required", "name", "error", "type", "labelHidden", "flow", "description"]);
    var _f = useState(type), inputType = _f[0], setInputType = _f[1];
    return (React.createElement(FieldGroup, { inline: inline, fullWidth: flow },
        React.createElement(Label, { htmlFor: id, required: required, hidden: labelHidden }, label),
        description && React.createElement(Description, null, description),
        type === 'textarea' ? (React.createElement(StyledTextarea, __assign({ name: name }, rest, { error: error ? true : false, ref: ref, id: id, required: required, type: type }))) : type === 'password' ? (React.createElement("div", { style: {
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
            } },
            React.createElement(StyledInput, __assign({ name: name }, rest, { error: error ? true : false, ref: ref, id: id, required: required, type: inputType })),
            React.createElement("span", { style: {
                    marginLeft: '-30px',
                }, onClick: function () {
                    return inputType === 'password'
                        ? setInputType('text')
                        : setInputType('password');
                } },
                React.createElement(Eye, null)))) : (React.createElement(StyledInput, __assign({ name: name }, rest, { error: error ? true : false, ref: ref, id: id, required: required, type: type }))),
        error && (React.createElement("span", { className: "fg-error", "data-testid": "t-".concat(name, "-error-message") }, error))));
});
export default Input;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
