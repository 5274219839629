// @ts-nocheck
import {
  ACHIEVEMENT_ENDPOINT,
  STUDY_ID,
  TRIAGE_API_VERSION,
  TRIAGE_ENDPOINT,
  COOKIE_ACM_ID,
  COOKIE_PARTICIPANT_AUTH,
  COOKIE_PORTAL_EMAIL,
} from '../constants';
import { get, remove, set } from 'js-cookie';
import axios from 'axios';
import { getQueryString } from '../utils';
import {
  parseParticipantAuthTokenExpiresHeader,
  storeAuthTokenExpiration,
} from '../utils/authExpiration';
import logger from '../utils/errorLogger';
import { userAgentMetaHeaders } from '../utils/fingerprint';
import { simpleUserLogout } from '../utils/simpleUserLogout';
import { PARTICIPANT_AUTH_TOKEN_EXPIRES_HEADER } from './constants';
import { DynamicObject } from '../types/utils';

class BillboardCookieParserError extends Error {
  constructor(err) {
    super(err);
    this.name = 'BillboardCookieParserError';
  }
}

class BillboardInvalidCookieError extends Error {
  constructor(message) {
    super(message);
    this.name = 'BillboardInvalidCookieError';
  }
}

const unauthorizedErrorCode = 401;
const cookieExpiry = 7;
export const CookieName = `${STUDY_ID}_`;
const PreviewTokenCookieName = `${CookieName}preview_token`;
export const sessionExpiredQueryParam = 'session-expired';

export const triage = axios.create({
  baseURL: `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/`,
});

export const triagev3 = axios.create({
  baseURL: `${TRIAGE_ENDPOINT}/api/v3/`,
});

export const portal = axios.create({
  baseURL: `${ACHIEVEMENT_ENDPOINT}/api/v4/`,
});

export const getPreviewToken = () => {
  // opt for preview token in url, if it exists we set it to the cookie
  if (getQueryString('preview_token')) {
    const previewToken = getQueryString('preview_token');
    set(PreviewTokenCookieName, previewToken, { expires: cookieExpiry });
    return previewToken;
  }
  return get(PreviewTokenCookieName) || '';
};

/**
 * @description Configure headers for async calls.
 * @param {Object} param0 - contains auth_token key
 * @returns {Object} Contains header configuration for Axios calls
 */
export const config = ({ auth_token = `` }) => ({
  headers: {
    'Participant-Auth-Token': auth_token,
    'Preview-Token': getPreviewToken(),
  },
});

/**
 * Takes an object containing the data from our primary cookie and returns a
 * version of it with only the valid attributes.
 * @param {Object} cookieData - The JSON data stored in the cookie
 * @returns {{
 *   status: string,
 *   connected_apps: string[],
 *   email: string,
 *   enrollment_identifier: string,
 *   tags: string[],
 *   participant_auth_token: string,
 *   phone_number: string,
 * }} - The same cookie data with only the valid attributes.
 */
const removeInvalidCookieData = (cookieData) => {
  return {
    status: cookieData.status,
    connected_apps: cookieData.connected_apps,
    email: cookieData.email,
    enrollment_identifier: cookieData.enrollment_identifier,
    tags: cookieData.tags,
    participant_auth_token: cookieData.participant_auth_token,
    phone_number: cookieData.phone_number,
    is_webview: cookieData.is_webview,
  };
};

const api = {
  meta: () => triage({ method: 'GET', url: '/meta', ...config({}) }),
  triage: {
    delete: (url, data, auth_token) =>
      triage({ method: 'DELETE', url, ...config({ auth_token }) }),
    get: (url, auth_token) =>
      triage({ method: 'GET', url, ...config({ auth_token }) }),
    patch: (url, data, auth_token) =>
      triage({ method: 'PATCH', url, data, ...config({ auth_token }) }),
    post: (url, data, auth_token) =>
      triage({ method: 'POST', url, data, ...config({ auth_token }) }),
    put: (url, data, auth_token) =>
      triage({ method: 'PUT', url, data, ...config({ auth_token }) }),
    process_contribution: (url, data, auth_token) =>
      triage({ method: 'PUT', url, data, ...config({ auth_token }) }),
    begin: (auth_token, enrollment_identifier, node_slug, data = {}) =>
      triage({
        method: 'PUT',
        url: `/enrollments/${enrollment_identifier}/nodes/${node_slug}/begin`,
        data: {},
        ...config({ auth_token }),
      }),
    /**
     * update_enrollment_node
     * @param {string} participant_auth_token
     * @param {string} enrollment_identifier
     * @param {string} node_slug
     * @param {{ status: string }} data
     * @description Sets the node status and returns useful redirect information
     *  Used by qualtrics_survey and connect ehr currently.
     * @returns
     */
    update_enrollment_node: (
      participant_auth_token,
      enrollment_identifier,
      node_slug,
      data,
    ) =>
      triagev3({
        method: 'PATCH',
        url: `/enrollments/${enrollment_identifier}/nodes/${node_slug}`,
        data,
        ...config({ auth_token: participant_auth_token }),
      }),
    verify_email: (auth_token, enrollment_identifier) =>
      triage({
        method: 'PUT',
        url: `/enrollments/${enrollment_identifier}/verify_email`,
        ...config({ auth_token }),
      }),
    send_token: (auth_token, enrollment_identifier, data) =>
      triagev3({
        method: 'POST',
        url: `/enrollments/${enrollment_identifier}/send_token`,
        data,
        ...config({ auth_token }),
      }),
    verify_token: (auth_token, enrollment_identifier, data) =>
      triagev3({
        method: 'POST',
        url: `/enrollments/${enrollment_identifier}/verify_token`,
        data,
        ...config({ auth_token }),
      }),
    // need to provide a random number so that iOS browser won't think it's the same url and returns cached response
    get_upload_url: (
      auth_token,
      enrollment_identifier,
      key,
      file_ext,
      bucket,
      rand,
    ) =>
      triage({
        method: 'GET',
        url: `/enrollments/${enrollment_identifier}/upload_secure?graph_id=${key}&file_ext=${file_ext}&bucket=${bucket}&rand=${rand}`,
        ...config({ auth_token }),
      }),
    survey: {
      goBack: ({
        actionType,
        idempotencyToken,
        occurrence,
        enrollmentIdentifier,
        surveyIdentifier,
      }: {
        actionType: string;
        idempotencyToken: string;
        enrollmentIdentifier: string;
        surveyIdentifier: string;
        occurrence?: any;
      }) =>
        triagev3.patch({
          url: `/enrollments/${enrollment_identifier}/surveys/${surveyIdentifier}/session`,
          data: {
            action_type: actionType,
            idempotency_token: idempotencyToken,
            occurrence: occurrence,
          },
        }),
    },
  },
  external: {
    takeSurvey: (enrollment_identifier, url) =>
      window.open(
        `${TRIAGE_ENDPOINT}/api/${TRIAGE_API_VERSION}/studies/${STUDY_ID}/enrollments/${enrollment_identifier}${url}`,
        '_blank',
      ),
    getInformedConsent: ({
      enrollment_identifier,
      slug,
      participant_auth_token,
      submission_key,
    }) =>
      triagev3({
        method: 'GET',
        url: `/studies/${STUDY_ID}/enrollments/${enrollment_identifier}/informed_consent_url?node_identifier=${slug}&submission_key=${submission_key}`,
        ...config({ auth_token: participant_auth_token }),
      }),
  },
  ehr: {
    list: (participant_auth_token) =>
      triagev3({
        method: 'GET',
        url: '/epic_organizations',
        ...config({ auth_token: participant_auth_token }),
      }),
    connected: (participant_auth_token, enrollment_identifier) =>
      triagev3({
        method: 'GET',
        url: `/enrollments/${enrollment_identifier}/epic_organizations`,
        ...config({ auth_token: participant_auth_token }),
      }),
    // Currently complete is only used by the ehr tile. Will consider moving in the future
    // if this endpoint is to be used more globally.
    complete: (
      participant_auth_token,
      enrollment_identifier,
      node_slug,
      data = { status: 'completed' },
    ) =>
      triagev3({
        method: 'PATCH',
        url: `/enrollments/${enrollment_identifier}/nodes/${node_slug}`,
        data,
        ...config({ auth_token: participant_auth_token }),
      }),
  },
  cognito: (study_slug, { meta, ...data }) =>
    triagev3({
      method: 'POST',
      url: `/studies/${study_slug}/token`,
      data,
      headers: meta ? userAgentMetaHeaders(meta) : null,
    }),
  accounts: (study_slug, data) =>
    triagev3({
      method: 'POST',
      url: `/studies/${study_slug}/accounts`,
      data,
    }),
  participantInfo: {
    updateRequired: (enrollment_identifier, auth_token) =>
      triagev3({
        method: 'GET',
        url: `/participant_personal_information/${enrollment_identifier}/update_required`,
        ...config({ auth_token }),
      }),
    data: (enrollment_identifier, auth_token) =>
      triagev3({
        method: 'GET',
        url: `/participant_personal_information/${enrollment_identifier}`,
        ...config({ auth_token }),
      }),
    update: (enrollment_identifier, auth_token, data) =>
      triagev3({
        method: 'PUT',
        url: `/participant_personal_information/${enrollment_identifier}`,
        ...config({ auth_token }),
        data: { user_data: data },
      }),
  },
  email: {
    update: (enrollment_identifier, auth_token, data) =>
      triagev3({
        method: 'PUT',
        url: `/enrollments/${enrollment_identifier}/email`,
        ...config({ auth_token }),
        data,
      }),
    verify: (data) =>
      triagev3({
        method: 'PUT',
        url: `/studies/${STUDY_ID}/email`,
        data,
      }),
  },
  withdraw: (enrollment_identifier, auth_token, data) =>
    triagev3({
      method: 'POST',
      url: `/enrollments/${enrollment_identifier}/withdraw`,
      ...config({ auth_token }),
      data,
    }),
  cookieStorage: {
    get: () => {
      const cookie = get(CookieName);
      if (!cookie) {
        return null;
      }
      try {
        const cookieData = JSON.parse(
          decodeURIComponent(decodeURIComponent(cookie)),
        );
        if (!cookieData.enrollment_identifier) {
          // This is not a valid state, but per SPP-2191, we have gotten related
          // AppSignal errors. Treat it as if the cookie didn't exist.
          logger.error(
            new BillboardInvalidCookieError(
              `Got a cookie with an empty enrollment_identifier (with participant_auth_token = '${cookieData.participant_auth_token}')`,
            ),
          );
          return null;
        }
        return removeInvalidCookieData(cookieData);
      } catch (err) {
        logger.error(new BillboardCookieParserError(err));
        return null;
      }
    },
    set: (d) => {
      // Per SPP-2191, these are invalid states that we have found some
      // participants to be in, which is causing problems. Since we don't yet
      // know the root cause, log the error and move on.
      if (!d) {
        logger.error(
          new BillboardInvalidCookieError(`Tried to set an empty cookie`),
        );
      } else if (d.enrollment_identifier === '') {
        logger.error(
          new BillboardInvalidCookieError(
            `Tried to set a cookie with an empty enrollment_identifier (with participant_auth_token = '${d.participant_auth_token}')`,
          ),
        );
      }
      set(CookieName, d, { expires: cookieExpiry });
    },
    remove: () => remove(CookieName),
  },
  local: {
    get: (keyName: string) => {
      const data = JSON.parse(
        decodeURIComponent(localStorage.getItem(keyName)),
      );
      if (!data) {
        return {};
      }
      return data;
    },
    set: (keyName: string, d: Record<string, any>) =>
      localStorage.setItem(keyName, JSON.stringify(d)),
    remove: (keyName: string) => localStorage.removeItem(keyName),
  },
  cookie: {
    get,
    remove,
    set,
  },
};

/**
 * Checks to see if we are displaying Billboard inside a webview from within the
 * study app.
 */
export const getIsWebview = () => !!api.cookieStorage.get()?.is_webview;

/**
 * @description Goes to triage and POSTS the url we will need to return to when the camcog test is completed.
 * @param {string} nodeIdentifier - comes Redux's participant object
 * @param {string} enrollmentIdentifier - unique string to the user who is participating in the study
 * @param {string} authToken - string token for the currently auth'd user.
 * @param {string} redirectUrl - the url where the user will be redirected to billboard when camcog test is done.
 * @returns {AxiosPromise}
 */
export const fetchCamCogRedirectUrl = (
  nodeIdentifier,
  enrollmentIdentifier,
  authToken,
) => {
  const data = {
    node_identifier: nodeIdentifier,
    enrollment_identifier: enrollmentIdentifier,
  };
  return triagev3({
    method: 'POST',
    url: '/camcog_visits/start_visit',
    data,
    ...config({ auth_token: authToken }),
  });
};
/**
 * @description Goes to triage and POSTS the completion of a camcog test by the user.
 * @param {string} nodeIdentifier - comes Redux's participant object
 * @param {string} enrollmentIdentifier - unique string to the user who is participating in the study
 * @param {string} authToken - string token for the currently auth'd user.
 * @returns {AxiosPromise}
 */
export const postCompletedCamCogVisit = (camCogSuccessToken) => {
  const data = {
    token: camCogSuccessToken,
  };

  return triagev3({
    method: 'POST',
    url: '/camcog_visits/complete_visit',
    data,
  });
};

export const handleErrorResponse = (
  error: unknown,
  // Used for mocking:
  {
    logger: _logger = logger,
    handleUnauthorizedError: _handleUnauthorizedError = handleUnauthorizedError,
  } = {},
): Promise<unknown> => {
  if (
    axios.isAxiosError(error) &&
    error.response?.status === unauthorizedErrorCode &&
    !error.response.config.url?.includes('/surveys/one_click_contribution')
  ) {
    _handleUnauthorizedError();
  }
  _logger.error(error);
  return Promise.reject(error);
};

const getStudyRootPath = () => {
  const studySlug = STUDY_ID;
  return `${window.location.origin}/${studySlug}`;
};

export const getSessionExpiredRedirectUrl = () =>
  `${getStudyRootPath()}?${sessionExpiredQueryParam}`;

export const handleUnauthorizedError = () => {
  simpleUserLogout(STUDY_ID);
  window.location.href = getSessionExpiredRedirectUrl();
};

const processAuthExpiration = (response) => {
  const expiresAt = response.headers?.[PARTICIPANT_AUTH_TOKEN_EXPIRES_HEADER];
  if (expiresAt) {
    storeAuthTokenExpiration(parseParticipantAuthTokenExpiresHeader(expiresAt));
  }
  return response;
};

triage.interceptors.response.use(processAuthExpiration, handleErrorResponse);

triagev3.interceptors.response.use(processAuthExpiration, handleErrorResponse);

export default api;

export const storeEnrollmentLocalStorage = (payload = {}) => {
  api.local.set(COOKIE_PARTICIPANT_AUTH, removeInvalidCookieData(payload));
  return payload;
};

export const fetchEnrollmentLocalStorage = () => {
  return api.local.get(COOKIE_PARTICIPANT_AUTH);
};

//
// Achievement Login
export const getAcmEmail = () =>
  api.cookie.get(COOKIE_PORTAL_EMAIL) || getQueryString('email');

export const getAcmCookie = () => api.cookie.get(COOKIE_ACM_ID);

/**
 * Submits an Informed Consent Form to Triage.
 *
 * @param data
 * @param data.authToken - The participant's auth token
 * @param data.nodeSlug - The slug of the Informed Consent node
 * @param data.payload - The payload in the form of a
 *   key/value mapping of form attributes
 * @param data.signed - Whether the ICF was signed or declined
 *   by the participant
 */
export const submitInformedConsent = ({
  authToken,
  enrollmentIdentifier,
  nodeSlug,
  payload,
  signed = true,
  recaptchaToken,
  otp_code,
}: {
  authToken: string;
  enrollmentIdentifier: string;
  nodeSlug: string;
  payload: DynamicObject;
  signed?: boolean;
  recaptchaToken?: string;
  otp_code?: string;
}) => {
  const contributions = Object.entries(payload).map(([key, value]) => ({
    attribute: key,
    value,
  }));
  if (otp_code) {
    contributions.push({ attribute: 'otp_code', value: otp_code });
  }
  const data = {
    contributions,
    signed,
  };
  if (recaptchaToken) {
    data.recaptcha_token = recaptchaToken;
    data.form_action = 'informed_consent';
  }
  return triagev3({
    url: `/enrollments/${enrollmentIdentifier}/informed_consents/${nodeSlug}`,
    method: 'POST',
    data,
    ...config({ auth_token: authToken }),
  });
};

/**
 * Declines an Informed Consent Form. This is a thin convenience wrapper over
 * {@link submitInformedConsent}.
 *
 * @param {Object} data
 * @param {string} data.authToken - The participant's auth token
 * @param {string} data.enrollmentIdentifier
 * @param {string} data.nodeSlug - The slug of the Informed Consent node
 * @returns {Promise<AxiosResponse>} - The response from Triage
 */
export const declineInformedConsent = ({
  authToken,
  enrollmentIdentifier,
  nodeSlug,
  payload = {},
}) =>
  submitInformedConsent({
    authToken,
    enrollmentIdentifier,
    nodeSlug,
    payload,
    signed: false,
  });

/**
 * Fetches all informed consent forms that the user has signed or declined.
 *
 * @param {Object} data
 * @param {string} data.authToken - The participant's auth token
 * @param {string} data.enrollmentIdentifier
 * @returns {Promise<AxiosResponse>} - The response containing the ICF data
 */
export const fetchInformedConsents = ({ authToken, enrollmentIdentifier }) => {
  return triagev3({
    url: `/enrollments/${enrollmentIdentifier}/informed_consents`,
    method: 'GET',
    ...config({ auth_token: authToken }),
  });
};

/**
 * Fetches the participant's contact information.
 *
 * @param {Object} data
 * @param {string} data.authToken
 * @param {string} data.enrollmentIdentifier
 * @returns {Promise<AxiosResponse>}
 */
export const fetchProfileData = ({ authToken, enrollmentIdentifier }) => {
  return triagev3({
    url: `/enrollments/${enrollmentIdentifier}/profile_data`,
    method: 'GET',
    ...config({ auth_token: authToken }),
  });
};

/**
 * Updates the participant's contact information.
 *
 * @param {Object} data
 * @param {string} data.authToken
 * @param {string} data.enrollmentIdentifier
 * @param {{[name: string]: string}} data.profileData
 * @returns {Promise<AxiosResponse>} - The response containing the updated
 *   information
 */
export const updateProfileData = ({
  authToken,
  enrollmentIdentifier,
  payload,
}) => {
  return triagev3({
    url: `/enrollments/${enrollmentIdentifier}/profile_data`,
    method: 'PUT',
    data: payload,
    ...config({ auth_token: authToken }),
  });
};
