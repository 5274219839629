// @ts-nocheck
import Cookie from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Dropdown } from '@evidation/ui';

import { useTranslationWithPrefix } from '../../hooks';

export const TEXT_SIZE_COOKIE_NAME = 'text-size';
const DEFAULT_TEXT_SIZE = 16;

const getStoredTextSize = () =>
  parseInt(Cookie.get(TEXT_SIZE_COOKIE_NAME), 10) || null;

const Container = styled.div`
  position: relative;
  & .menu {
    & .menu-button {
      font-weight: 400;
      font-size: 1rem;
      color: #000;
    }
  }
`;

export default function TextSizeSelect() {
  const { t } = useTranslationWithPrefix('components.header.textSizeSelect');

  const [selectedSize, setSelectedSize] = useState(
    getStoredTextSize() || DEFAULT_TEXT_SIZE,
  );

  useEffect(() => {
    Cookie.set(TEXT_SIZE_COOKIE_NAME, selectedSize, { expires: 365 });
  }, [selectedSize]);

  const selectSize = (size) => setSelectedSize(size);

  return (
    <Container className="navbar--item">
      <Dropdown
        text={t('aPlus')}
        variant="ghost"
        value={selectedSize}
        onChange={(value) => selectSize(value)}
        options={[
          {
            label: (
              <>
                <span className="label">{t('small')}</span> &ndash; 16{t('pt')}
              </>
            ),
            value: 16,
          },
          {
            label: (
              <>
                <span className="label">{t('medium')}</span> &ndash; 18{t('pt')}
              </>
            ),
            value: 18,
          },
          {
            label: (
              <>
                <span className="label">{t('large')}</span> &ndash; 20{t('pt')}
              </>
            ),
            value: 20,
          },
        ]}
      />

      <Helmet>
        <style>
          {`
            html {
              font-size: ${selectedSize}px;
            }
          `}
        </style>
      </Helmet>
    </Container>
  );
}
