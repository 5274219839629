"use strict";

require("core-js/modules/es.object.define-property.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NumberInput = void 0;
Object.defineProperty(exports, "validations", {
  enumerable: true,
  get: function get() {
    return _validations.validations;
  }
});
var _components = require("../../components");
var _validations = require("./validations");
var local = exports.NumberInput = _components.NumberInput;
local.handlesErrorDisplay = true;
local.showWarningIconOnError = false;