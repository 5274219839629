export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="155"
    height="61"
    role="graphics-symbol"
  >
    <defs>
      <path id="A" d="M0 .077h55.814v51.32H0z" />
      <path
        id="B"
        d="M115.375 10.486h-1.28V3.919h1.28v.984c.394-.788 1.336-1.125 2.053-1.125 1.631 0 2.531 1.069 2.531 2.911v3.797h-1.28V6.773c0-1.097-.548-1.744-1.448-1.744-.928 0-1.856.52-1.856 1.913v3.544"
      />
      <path
        id="C"
        d="M133.347 7.181c0-1.322-.984-2.152-2.165-2.152s-2.166.83-2.166 2.152a2.12 2.12 0 0 0 2.166 2.208 2.12 2.12 0 0 0 2.165-2.208zm-5.597 0c0-2.081 1.561-3.403 3.431-3.403s3.445 1.322 3.445 3.403-1.575 3.445-3.445 3.445-3.431-1.364-3.431-3.445h0z"
      />
      <path
        id="D"
        d="M90.921 26.736h-1.28v-6.567h1.28v1.476c.338-1.153.97-1.617 1.828-1.617.464 0 .956.127 1.139.253l-.197 1.195c-.352-.169-.703-.225-.97-.225-1.266 0-1.8 1.35-1.8 3.094v2.39"
      />
      <path
        id="E"
        d="M137.313 26.736h1.265v-6.567h-1.265v6.567zm.633-9.815c.492 0 .886.352.886.83s-.394.83-.886.83c-.478 0-.886-.352-.886-.83s.408-.83.886-.83h0z"
      />
      <path
        id="F"
        d="M93.073 59.236h1.266v-6.567h-1.266v6.567zm.633-9.815c.492 0 .886.352.886.83s-.394.83-.886.83c-.478 0-.886-.352-.886-.83s.408-.83.886-.83h0z"
      />
      <path
        id="G"
        d="M99.639 52.669v1.083h-1.392v5.484h-1.28v-5.484h-1.181v-1.083h1.181v-2.475h1.28v2.475h1.392"
      />
    </defs>
    <g transform="translate(0 .588)" fill="none" fillRule="evenodd">
      <g fill="#0a0b09">
        <path d="M82.779 2.02v3.614h2.461c1.181 0 1.659-.9 1.659-1.8 0-.886-.464-1.814-1.589-1.814h-2.531zM85.324.797c2.053 0 2.883 1.519 2.883 3.037 0 1.533-.83 3.023-2.883 3.023h-2.545v3.628h-1.322V.797h3.867 0zm7.158 4.233c-1.181 0-2.137.844-2.137 2.152 0 1.294.956 2.194 2.137 2.194 1.167 0 2.18-.802 2.18-2.194 0-1.406-1.125-2.152-2.18-2.152zm2.194 4.444c-.436.745-1.392 1.153-2.306 1.153-1.8 0-3.333-1.35-3.333-3.431 0-2.095 1.533-3.417 3.333-3.417.914 0 1.87.38 2.306 1.125V3.92h1.266v6.567h-1.266V9.473h0zm4.683 1.012h-1.28V3.919h1.28v1.476c.338-1.153.97-1.617 1.828-1.617.464 0 .956.127 1.139.253l-.197 1.195a2.28 2.28 0 0 0-.97-.225c-1.266 0-1.8 1.35-1.8 3.094v2.39m5.54.001h-1.265V.347h1.265v5.864l2.264-2.292h1.744l-2.812 2.84 3.487 3.726h-1.687L104.9 7.279v3.206m5.793.001h1.265V3.919h-1.265v6.567zm.633-9.815c.492 0 .886.352.886.83s-.394.83-.886.83c-.478 0-.886-.352-.886-.83s.408-.83.886-.83h0z" />
        <use xlinkHref="#B" />
        <path d="M121.746 5.452c.098-.956 1.111-1.673 2.278-1.673 1.111 0 2.166.52 2.278 1.856h-1.223c-.07-.492-.52-.745-1.026-.745-.478 0-.886.239-.984.591-.056.211-.056.366.014.52.126.267.436.408.773.506l.97.239c1.364.338 1.701 1.167 1.701 1.912 0 1.223-1.181 1.969-2.545 1.969-1.181 0-2.405-.759-2.447-2.109h1.167c.042.281.225.591.534.745.281.169.619.253 1.04.225.591-.042.984-.38.998-.858s-.394-.717-.872-.83l-.9-.211c-1.195-.324-1.87-.928-1.758-2.137" />
        <use xlinkHref="#C" />
        <use xlinkHref="#B" x="22.233" />
        <path d="M143.683 3.834c.506-.717.9-1.744.886-2.967h1.167c-.127 1.589-.745 2.77-1.308 3.234l-.745-.267m3.207 1.618c.098-.956 1.111-1.673 2.278-1.673 1.111 0 2.165.52 2.278 1.856h-1.223c-.07-.492-.52-.745-1.026-.745-.478 0-.886.239-.984.591-.056.211-.056.366.014.52.126.267.436.408.773.506l.97.239c1.364.338 1.701 1.167 1.701 1.912 0 1.223-1.181 1.969-2.545 1.969-1.181 0-2.405-.759-2.447-2.109h1.167c.042.281.225.591.534.745.281.169.619.253 1.041.225.591-.042.984-.38.998-.858s-.394-.717-.872-.83l-.9-.211c-1.195-.324-1.87-.928-1.758-2.137M82.779 18.27v3.614h2.461c1.181 0 1.659-.9 1.659-1.8 0-.886-.464-1.814-1.589-1.814h-2.531zm2.545-1.223c2.053 0 2.883 1.519 2.883 3.037 0 1.533-.83 3.023-2.883 3.023h-2.545v3.628h-1.322v-9.689h3.867 0z" />
        <use xlinkHref="#D" />
        <use xlinkHref="#C" x="-33.159" y="16.25" />
        <path d="M106.179 21.28c-1.181 0-2.137.9-2.137 2.18 0 1.336.956 2.165 2.137 2.165 1.055 0 2.18-.773 2.18-2.165 0-1.406-1.012-2.18-2.18-2.18zm-.127 7.523c1.42 0 2.334-.802 2.334-2.981 0-.056-.014-.098-.014-.155-.394.773-1.392 1.209-2.306 1.209-1.8 0-3.333-1.322-3.333-3.403 0-2.095 1.533-3.445 3.333-3.445.914 0 1.87.408 2.306 1.167v-1.026h1.266v5.568c0 3.094-1.42 4.275-3.586 4.275-1.209 0-2.334-.619-2.967-1.772.169-.07.9-.478 1.069-.562.394.731 1.167 1.125 1.898 1.125h0zm6.989-2.067h-1.28v-6.567h1.28v1.476c.337-1.153.97-1.617 1.828-1.617.464 0 .956.127 1.139.253l-.197 1.195a2.28 2.28 0 0 0-.97-.225c-1.265 0-1.8 1.35-1.8 3.094v2.39m9.028-3.796c-.155-1.097-.942-1.758-1.997-1.758-1.083 0-1.927.661-2.081 1.758h4.078zm1.252.998h-5.329c.155 1.097 1.012 1.786 2.123 1.786.788 0 1.519-.366 1.884-1.012l1.097.422c-.562 1.153-1.758 1.744-3.023 1.744-1.87 0-3.361-1.364-3.361-3.445s1.491-3.403 3.361-3.403 3.262 1.322 3.262 3.403l-.014.506h0zm1.378-2.235c.098-.956 1.111-1.673 2.278-1.673 1.111 0 2.166.52 2.278 1.856h-1.223c-.07-.492-.52-.745-1.026-.745-.478 0-.886.239-.984.59-.056.211-.056.366.014.52.127.267.436.408.773.506l.97.239c1.364.337 1.702 1.167 1.702 1.912 0 1.223-1.181 1.969-2.545 1.969-1.181 0-2.405-.759-2.447-2.109h1.167c.042.281.225.591.534.745.281.169.619.253 1.04.225.591-.042.984-.38.998-.858s-.394-.717-.872-.83l-.9-.211c-1.195-.323-1.87-.928-1.758-2.137m6.174.001c.098-.956 1.111-1.673 2.278-1.673 1.111 0 2.166.52 2.278 1.856h-1.223c-.07-.492-.52-.745-1.026-.745-.478 0-.886.239-.984.59-.056.211-.056.366.014.52.126.267.436.408.773.506l.97.239c1.364.337 1.702 1.167 1.702 1.912 0 1.223-1.181 1.969-2.545 1.969-1.181 0-2.405-.759-2.447-2.109h1.167c.042.281.225.591.534.745.281.169.619.253 1.04.225.591-.042.984-.38.998-.858s-.394-.717-.872-.83l-.9-.211c-1.195-.323-1.87-.928-1.758-2.137" />
        <use xlinkHref="#E" />
        <path d="M145.877 23.431c0-1.322-.984-2.152-2.165-2.152s-2.165.83-2.165 2.152a2.12 2.12 0 0 0 2.165 2.208 2.12 2.12 0 0 0 2.165-2.208zm-5.597 0c0-2.081 1.561-3.403 3.431-3.403s3.445 1.322 3.445 3.403-1.575 3.445-3.445 3.445-3.431-1.364-3.431-3.445h0zm9.858 3.305h-1.28v-6.567h1.28v.984c.394-.787 1.336-1.125 2.053-1.125 1.631 0 2.531 1.069 2.531 2.911v3.797h-1.28v-3.712c0-1.097-.548-1.744-1.448-1.744-.928 0-1.856.52-1.856 1.912v3.544M90.12 33.297h1.195l1.181 9.689h-1.294l-.886-7.284-3.023 6.215H86.14l-3.051-6.215-.886 7.284h-1.294l1.181-9.689h1.195l3.417 7.115 3.417-7.115m7.144 4.233c-1.181 0-2.137.844-2.137 2.152 0 1.294.956 2.194 2.137 2.194 1.167 0 2.18-.802 2.18-2.194 0-1.406-1.125-2.152-2.18-2.152zm2.194 4.444c-.436.745-1.392 1.153-2.306 1.153-1.8 0-3.333-1.35-3.333-3.431 0-2.095 1.533-3.417 3.333-3.417.914 0 1.87.38 2.306 1.125v-.984h1.266v6.567h-1.266v-1.013h0z" />
        <use xlinkHref="#D" x="13.219" y="16.25" />
        <path d="M109.68 42.986h-1.266V32.847h1.266v5.864l2.264-2.292h1.744l-2.812 2.84 3.487 3.726h-1.687l-2.995-3.206v3.206m10.307-3.796c-.155-1.097-.942-1.758-1.997-1.758-1.083 0-1.927.661-2.081 1.758h4.078zm1.252.998h-5.33c.155 1.097 1.013 1.786 2.123 1.786.787 0 1.519-.366 1.884-1.013l1.097.422c-.562 1.153-1.758 1.744-3.023 1.744-1.87 0-3.361-1.364-3.361-3.445s1.49-3.403 3.361-3.403 3.262 1.322 3.262 3.403l-.014.506h0zm2.925 2.799h-1.28v-6.567h1.28v1.476c.337-1.153.97-1.617 1.828-1.617.464 0 .956.127 1.139.253l-.197 1.195a2.28 2.28 0 0 0-.97-.225c-1.266 0-1.8 1.35-1.8 3.094v2.39m3.951-5.033c.098-.956 1.111-1.673 2.278-1.673 1.111 0 2.166.52 2.278 1.856h-1.224c-.07-.492-.52-.745-1.026-.745-.478 0-.886.239-.984.59-.056.211-.056.366.014.52.127.267.436.408.773.506l.97.239c1.364.338 1.702 1.167 1.702 1.912 0 1.223-1.181 1.969-2.545 1.969-1.181 0-2.405-.759-2.447-2.109h1.167c.042.281.225.591.534.745.281.169.619.253 1.041.225.591-.042.984-.38.998-.858s-.394-.717-.872-.83l-.9-.211c-1.195-.323-1.87-.928-1.758-2.137M81.457 59.236h1.308v-9.689h-1.308zm4.978 0h-1.28v-6.567h1.28v.984c.394-.788 1.336-1.125 2.053-1.125 1.631 0 2.531 1.069 2.531 2.911v3.797h-1.28v-3.712c0-1.097-.549-1.744-1.449-1.744-.928 0-1.856.52-1.856 1.913v3.544" />
        <use xlinkHref="#F" />
        <use xlinkHref="#G" />
        <use xlinkHref="#F" x="8.015" />
        <path d="M107.5 53.78c-1.181 0-2.138.844-2.138 2.152 0 1.294.956 2.194 2.138 2.194 1.167 0 2.18-.802 2.18-2.194 0-1.406-1.125-2.152-2.18-2.152zm2.194 4.444c-.436.745-1.392 1.153-2.306 1.153-1.8 0-3.333-1.35-3.333-3.431 0-2.095 1.533-3.417 3.333-3.417.914 0 1.87.38 2.306 1.125v-.984h1.266v6.567h-1.266v-1.012h0z" />
        <use xlinkHref="#G" x="16.622" />
        <use xlinkHref="#E" x="-19.603" y="32.5" />
        <path d="M121.633 52.669l1.997 4.936 1.983-4.936h1.364l-2.742 6.567h.014-1.237l-2.728-6.567h1.35m11.417 2.77c-.155-1.097-.942-1.758-1.997-1.758-1.083 0-1.926.661-2.081 1.758h4.078zm1.252.998h-5.329c.155 1.097 1.012 1.786 2.123 1.786.787 0 1.518-.366 1.884-1.013l1.097.422c-.563 1.153-1.758 1.744-3.023 1.744-1.87 0-3.361-1.364-3.361-3.445s1.49-3.403 3.361-3.403 3.262 1.322 3.262 3.403l-.014.506h0z" />
      </g>
      <g transform="translate(0 8.502)">
        <mask id="H" fill="#fff">
          <use xlinkHref="#A" />
        </mask>
        <path
          d="M37.23 26.809a8.2 8.2 0 0 1-8.186-8.186 8.2 8.2 0 0 1 8.186-8.186c4.514 0 8.186 3.672 8.186 8.186a8.2 8.2 0 0 1-8.186 8.186zm9.592-8.186c0-5.289-4.303-9.592-9.592-9.592s-9.592 4.303-9.592 9.592 4.303 9.592 9.592 9.592 9.592-4.303 9.592-9.592h0zM11.569 30.882c-1.802 1.802-2.794 4.198-2.794 6.746 0 5.261 4.28 9.54 9.54 9.54s9.54-4.28 9.54-9.54c0-2.841-1.517-5.152-2.984-7.386-.486-.74-.944-1.438-1.344-2.148-1.99-3.535-2.958-6.705-2.958-9.691 0-9.33 7.59-16.92 16.92-16.92s16.92 7.59 16.92 16.92a16.95 16.95 0 0 1-3.786 10.667 16.98 16.98 0 0 1-9.475 5.856l.303 1.373c4.012-.884 7.656-3.137 10.263-6.342a18.37 18.37 0 0 0 4.101-11.555C55.814 8.298 47.593.077 37.488.077S19.162 8.298 19.162 18.403a15.74 15.74 0 0 0 .027.866 10.31 10.31 0 0 0-1.003-.05 18 18 0 0 0-8.182 1.94A18.11 18.11 0 0 0 0 37.406h1.406c0-6.373 3.537-12.117 9.231-14.99a16.61 16.61 0 0 1 7.55-1.79 8.18 8.18 0 0 1 1.146.078 18.64 18.64 0 0 0 .64 2.803 14.19 14.19 0 0 0-1.646-.097c-7.718 0-13.998 6.279-13.998 13.998s6.279 13.998 13.998 13.998V50A12.61 12.61 0 0 1 5.736 37.408a12.61 12.61 0 0 1 12.592-12.592 12.73 12.73 0 0 1 2.062.168l.103.009c.474 1.226 1.074 2.486 1.809 3.79.423.752.917 1.503 1.394 2.23 1.354 2.061 2.754 4.193 2.754 6.614 0 4.485-3.649 8.134-8.134 8.134s-8.134-3.649-8.134-8.134a8.08 8.08 0 0 1 2.382-5.752l-.995-.994"
          mask="url(#H)"
          fill="#eb8b30"
        />
      </g>
      <g fill="#eb8b30">
        <path d="M54.734 41.802a22.83 22.83 0 0 0 5.422-14.768c0-12.584-10.238-22.822-22.822-22.822a22.88 22.88 0 0 0-16.863 7.444l1.039.948a21.47 21.47 0 0 1 15.824-6.985c11.809 0 21.416 9.607 21.416 21.416 0 5.075-1.807 9.996-5.087 13.858a21.44 21.44 0 0 1-12.639 7.242l.24 1.385c5.23-.908 10.014-3.649 13.47-7.717m8.356-14.588c0 8.1-3.693 15.573-10.132 20.504l.855 1.117a27.47 27.47 0 0 0 7.777-9.37 26.93 26.93 0 0 0 2.907-12.25H63.09" />
        <path d="M37.282 1.406c9.55 0 18.28 5.238 22.782 13.671l1.241-.662A27.19 27.19 0 0 0 37.282 0c-3.674 0-7.238.72-10.594 2.139l.548 1.295a25.65 25.65 0 0 1 10.046-2.028m-22.76 44.53c0-2.084 1.696-3.78 3.78-3.78s3.78 1.696 3.78 3.78-1.696 3.78-3.78 3.78-3.78-1.696-3.78-3.78zm8.967 0c0-2.86-2.327-5.186-5.186-5.186s-5.186 2.327-5.186 5.186 2.327 5.186 5.186 5.186 5.186-2.327 5.186-5.186h0z" />
        <path d="M24.755 27.034c0-6.95 5.654-12.604 12.605-12.604s12.605 5.654 12.605 12.604a12.57 12.57 0 0 1-3.302 8.505l1.038.949c2.367-2.587 3.67-5.945 3.67-9.454a14.03 14.03 0 0 0-14.011-14.011 14.03 14.03 0 0 0-14.011 14.011c0 4.224 1.906 7.339 4.028 10.42 1.432 2.078 3.336 5.237 3.336 8.663a12.48 12.48 0 0 1-3.903 9.114l.97 1.018c2.798-2.668 4.339-6.266 4.339-10.131 0-3.818-2.046-7.228-3.584-9.461-1.992-2.891-3.78-5.802-3.78-9.622" />
      </g>
    </g>
  </svg>
);
