var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
export var CloseCircleFilled = function (props) {
    return (React.createElement("svg", __assign({ width: "18", height: "18", viewBox: "0 0 18 18", fill: "none", xmlns: "http://www.w3.org/2000/svg", role: "graphics-symbol" }, props),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M0.25 9C0.25 4.16797 4.16797 0.25 9 0.25C13.832 0.25 17.75 4.16797 17.75 9C17.75 13.832 13.832 17.75 9 17.75C4.16797 17.75 0.25 13.832 0.25 9ZM11.6457 5.2264C11.7707 5.10143 11.9733 5.10143 12.0983 5.2264L12.7737 5.90185C12.8987 6.02682 12.8987 6.22943 12.7737 6.3544L10.128 9.00012L12.7737 11.6458C12.8987 11.7708 12.8987 11.9734 12.7737 12.0984L12.0983 12.7738C11.9733 12.8988 11.7707 12.8988 11.6457 12.7738L9 10.1281L6.35427 12.7738C6.22931 12.8988 6.02669 12.8988 5.90173 12.7738L5.22627 12.0984C5.10131 11.9734 5.10131 11.7708 5.22627 11.6458L7.872 9.00012L5.22627 6.3544C5.10131 6.22943 5.10131 6.02682 5.22627 5.90185L5.90173 5.2264C6.02669 5.10143 6.22931 5.10143 6.35427 5.2264L9 7.87212L11.6457 5.2264Z", fill: props.fill || '#1A1A1A' })));
};
